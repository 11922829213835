import React from 'react';
import RequestList from '../components/RequestList';

function Requests() {
  return (
    <div>
      <h1>My Requests</h1>
      <RequestList />
    </div>
  );
}

export default Requests;