import React, { useState } from 'react';
import api from '../utils/api';
import { useAuth } from '../context/AuthContext';
import './RequestForm.css';
import Notification from './Notification';

const RequestForm = ({ onRequestSubmitted }) => {
  const { user } = useAuth();
  // Remove this log - security risk exposing user details
  // console.log('Current authenticated user:', user);

  const [type, setType] = useState('bug');
  const [description, setDescription] = useState('');
  const [product, setProduct] = useState('OliviaXpense (IOS)');
  const [file, setFile] = useState(null);
  const [notification, setNotification] = useState({ show: false, message: '', type: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const resetForm = () => {
    setType('bug');
    setDescription('');
    setProduct('OliviaXpense (IOS)');
    setFile(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      if (!user) {
        setError('User not authenticated. Please log in again.');
        setIsSubmitting(false);
        return;
      }
      
      const userId = user._id || user.id;
      if (!userId) {
        setError('User ID not found. Please log in again.');
        setIsSubmitting(false);
        return;
      }
      
      // Create form data
      const formData = new FormData();
      formData.append('type', type);
      formData.append('description', description);
      formData.append('product', product);
      formData.append('userId', userId);
      
      // Only append file if one is selected
      if (file) {
        // Keep minimal logging for file uploads - just name and size is safer
        console.log('Adding file to request:', file.name, file.size);
        formData.append('file', file);
      }
      
      const response = await api.post('/api/requests', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      // Safe to log just confirmation without response data
      console.log('Request submitted successfully');
      setSuccess('Request submitted successfully!');
      resetForm();
      if (onRequestSubmitted) onRequestSubmitted(response.data);
    } catch (error) {
      // Keep error logging but don't expose full error object
      console.error('Request submission error:', error.message);
      setError(error.response?.data?.message || 'Failed to submit request');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form className="request-form" onSubmit={handleSubmit} encType="multipart/form-data">
        {error && <div className="error">{error}</div>}
        {success && <div className="success">{success}</div>}
        <div className="form-group">
          <label>
            Product:
            <select value={product} onChange={(e) => setProduct(e.target.value)} required>
              <option value="OliviaXpense (IOS)">OliviaXpense (IOS)</option>
              <option value="OliviaXpense (Android)">OliviaXpense (Android)</option>
              <option value="Olivia Command Center">Olivia Command Center</option>
              <option value="Olivia SmartXtract">Olivia SmartXtract</option>
            </select>
          </label>
        </div>
        <div className="form-group">
          <label>
            Request Type:
            <select value={type} onChange={(e) => setType(e.target.value)}>
              <option value="feature">Feature</option>
              <option value="bug">Bug</option>
              <option value="customization">Customization</option>
            </select>
          </label>
        </div>
        <div className="form-group">
          <label>
            Description:
            <textarea 
              value={description} 
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describe your request. Please include details of the affected user/s..."
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label>Attachment (Optional)</label>
          <input
            type="file"
            accept="image/jpeg,image/png,video/mp4"
            onChange={(e) => setFile(e.target.files[0])}
          />
          <small>Max size: 50MB. Supported: JPEG, PNG, MP4</small>
        </div>
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </form>
      {notification.show && (
        <Notification 
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification({ show: false, message: '', type: '' })}
        />
      )}
    </>
  );
};

export default RequestForm;