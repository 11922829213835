import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Login from './components/Login';
import Home from './pages/home';
import Requests from './pages/Requests';
import { useAuth } from './context/AuthContext';
import './App.css';
import ChangePassword from './components/ChangePassword';

// Define AuthRoute component
const AuthRoute = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation();
  
  if (!user) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return children;
};

function Navigation() {
  const { user, logout } = useAuth();
  const location = useLocation();
  
  // Hide navigation on login page
  if (location.pathname === '/login' || !user) {
    return null;
  }

  return (
    <nav className="nav-bar">
      <div className="nav-links">
        <Link to="/home" replace>Submit Request</Link>
        <Link to="/requests" replace>My Requests</Link>
        <Link to="/change-password" replace>Change Password</Link>
      </div>
      <button onClick={logout}>Logout</button>
    </nav>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Navigation />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/home" element={
              <AuthRoute>
                <Home />
              </AuthRoute>
            } />
            <Route path="/requests" element={
              <AuthRoute>
                <Requests />
              </AuthRoute>
            } />
            <Route path="/change-password" element={
              <AuthRoute>
                <ChangePassword />
              </AuthRoute>
            } />
            <Route path="/" element={<Navigate to="/login" replace />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
