import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import RequestCard from './RequestCard';
import api from '../utils/api'; // Add this import
import './RequestList.css';

const RequestList = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        // Check if token exists before making request
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No authentication token found');
          setError('Authentication required');
          return;
        }

        console.log('Fetching requests with user ID:', user?.id);
        const response = await api.get(`/api/requests?userId=${user?.id}`);
        setRequests(response.data);
      } catch (error) {
        console.error('Error fetching requests:', error);
        setError('Failed to fetch requests');
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, [user]);

  const sortedRequests = [...requests].sort((a, b) => 
    new Date(b.dateSubmitted) - new Date(a.dateSubmitted)
  );

  return (
    <div className="request-list">
      {sortedRequests.map(request => (
        <RequestCard key={request._id} request={request} />
      ))}
    </div>
  );
}

export default RequestList;