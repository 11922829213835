import React, { useState, useEffect } from 'react';
import './RequestCard.css';
import { FaPaperclip } from 'react-icons/fa';

function RequestCard({ request }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [selectedType, setSelectedType] = useState(null); // 'image' or 'video'

  const handlePreview = (attachmentUrl, mimeType) => {
    setSelectedAttachment(attachmentUrl);
    setSelectedType(mimeType.includes("video") ? "video" : "image");
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedAttachment(null);
    setSelectedType(null);
  };

  // Disable scrolling when modal is open
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };
    if (modalOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
      window.addEventListener("keydown", handleKeyDown);
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [modalOpen]);

  return (
    <div className="request-card">
      <div className="request-header">
        <div className="header-left">
          <span className={`type-badge ${request.type}`}>{request.type}</span>
          <h4 className="request-id">{request.requestId}</h4>
        </div>
        <div className="header-right">
          {request.attachments && request.attachments.length > 0 && request.attachments[0].url && (
            <FaPaperclip
              className="attachment-icon"
              onClick={() => handlePreview(request.attachments[0].url, request.attachments[0].mimetype)}
            />
          )}
          <span className={`status ${request.status}`}>{request.status}</span>
        </div>
      </div>
      <p className="description">{request.description}</p>
      <div className="meta">
        <span className="date">Submitted: {new Date(request.dateSubmitted).toLocaleString()}</span>
        <span className="product-label">{request.product}</span>
      </div>
      {request.comments && request.comments.length > 0 && (
        <div className="comments-section">
          {request.comments.map(comment => (
            <div key={comment._id} className="comment">
              <p>{comment.text}</p>
              <small>
                By {comment.adminEmail} on {new Date(comment.createdAt).toLocaleString()}
              </small>
            </div>
          ))}
        </div>
      )}

      {/* Attachment Preview Modal */}
      {modalOpen && selectedAttachment && (
        <div className="modal-overlay" onClick={() => closeModal()}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {selectedType === "image" ? (
              <img src={selectedAttachment} alt="Preview" className="preview-media" />
            ) : (
              <video controls className="preview-media">
                <source src={selectedAttachment} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            <button className="close-button" onClick={() => closeModal()}>✖</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default RequestCard;
