import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Combined request interceptor for logging and token
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');

  
  // Add token to headers if available
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  
  // For production, use minimal logging or remove completely
  if (process.env.NODE_ENV !== 'production') {
    console.log(`Request: ${config.method.toUpperCase()} ${config.url}`);
  }
  
  return config;
});

api.interceptors.response.use(
  (response) => {
    // For production, use minimal logging or remove completely
    if (process.env.NODE_ENV !== 'production') {
      console.log(`Response: ${response.status}`);
    }
    return response;
  },
  (error) => {
    // Keep essential error logging but remove sensitive data
    console.error('API Error:', {
      status: error.response?.status,
      message: error.message
    });
    
    if (error.message === 'Network Error') {
      console.error('Network connectivity issue');
    }

    if (error.response?.status === 401) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    
    return Promise.reject(error);
  }
);

export default api;