import React from 'react';
import RequestForm from '../components/RequestForm';
import { useAuth } from '../context/AuthContext';
import './Home.css';

function Home() {
  const { user } = useAuth();

  return (
    <div className="home-container">
      <h1 className="home-title">Hi {user?.email}, how can we help you?</h1>
      <div className="form-container">
        <RequestForm />
      </div>
    </div>
  );
}

export default Home;