import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../utils/api'; // Correct path to your API utility

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    const savedUser = localStorage.getItem('user');
    
    console.log('Auth state:', {
      hasToken: !!token,
      hasUser: !!savedUser
    });

    if (!token || !savedUser) {
      console.log('No auth data found');
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      setUser(null);
    }
  }, []);

  const login = (userData) => {
    const { token, user } = userData;
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    setUser(user); // Make sure this line exists
    console.log('User logged in:', { 
      email: user.email,
      // Don't log any part of the token
      tokenReceived: true
    });
    console.log('User logged in:', { userId: user.id });
    if (process.env.NODE_ENV !== 'production') {
      console.log('User logged in:', { email: user.email });
    }
  };

  const logout = async () => {
    try {
      console.log('Logout successful');
      
      // Force a complete page refresh
      window.location.replace('/login'); // Better than href - replaces current history entry
    } catch (error) {
      console.error('Logout error:', error);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      setUser(null);
      window.location.replace('/login');
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);